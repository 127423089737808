<template>
   <v-layout align-start>
      <v-flex xs12>
         <!-- SI ESTA VERIFICADO -->
         <v-container v-if="verificado" fluid>
            <!-- SI NO TIENE MORA -->
            <v-layout
               v-if="checkRangoAlto()"
               justify-center
               wrap
               xs4
               class="text-center mt-2"
            >
               <v-layout justify-center wrap>
                  <v-flex xs4>
                     <v-autocomplete
                        v-model="selectCiclo"
                        :items="cmbCiclos"
                        class="mr-1"
                        color="primary"
                        item-text="nombre"
                        item-value="nombre"
                        label="Ciclos"
                        no-data-text="Sin resultados"
                        placeholder="Seleccione un ciclo..."
                     >
                     </v-autocomplete>
                  </v-flex>
                  <v-flex xs2>
                     <v-autocomplete
                        v-model="selectFiltro"
                        :items="cmbFiltros"
                        class="ml-1"
                        color="primary"
                        item-text="nombre"
                        item-value="id"
                        label="Filtro"
                        no-data-text="Sin resultados"
                        placeholder="Seleccionar..."
                     >
                     </v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                     <v-img
                        src="/assets/thumbs-up-400.png"
                        class="tiltImage"
                        contain
                        height="145"
                     ></v-img>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-8">
                     <span class="font-weight-medium text-h3">
                        {{
                           talonario === undefined
                              ? 'TALONARIO AUN NO GENERADO'
                              : talonario.nombre
                        }}
                     </span>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-2">
                     <span class="font-weight-medium" style="font-size: 24px">
                        Obtén tu talonario {{ selectCiclo }}.
                        <br />
                        <v-btn
                           color="primary"
                           :disabled="checkingPagos"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4'
                           "
                           width="200"
                           @click="
                              checkingPagos
                                 ? false
                                 : generarReporteTalonario(
                                      'save',
                                      true,
                                      selectFiltro == 1
                                         ? cuotasImpresion.toString()
                                         : null
                                   )
                           "
                        >
                           Descargar
                        </v-btn>
                        <v-btn
                           color="primary"
                           :disabled="checkingPagos"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4'
                           "
                           width="200"
                           @click="
                              checkingPagos
                                 ? false
                                 : generarReporteTalonario(
                                      $vuetify.breakpoint.smAndUp
                                         ? 'open'
                                         : 'save',
                                      true,
                                      selectFiltro == 1
                                         ? cuotasImpresion.toString()
                                         : null
                                   )
                           "
                        >
                           Abrir en el Navegador
                        </v-btn>
                     </span>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-2">
                     <br />
                     <span class="font-weight-medium" style="font-size: 24px">
                        Puedes salir de esta página haciendo clic en el 👍.
                     </span>
                  </v-flex>
               </v-layout>
            </v-layout>
            <v-layout v-else>
               <v-layout v-if="!poseeMora" justify-center wrap>
                  <v-flex xs12>
                     <v-img
                        src="/assets/thumbs-up-400.png"
                        class="tiltImage"
                        contain
                        height="145"
                        @click="sacameDeAqui"
                     ></v-img>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-8">
                     <span class="font-weight-medium text-h3">
                        {{
                           talonario === undefined
                              ? 'TALONARIO AUN NO GENERADO'
                              : talonario.nombre
                        }}
                     </span>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-2">
                     <span class="font-weight-medium" style="font-size: 24px">
                        Obtén tu talonario {{ cicloActual }}.
                        <br />
                        <v-btn
                           color="primary"
                           :disabled="disabledTalonario"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4'
                           "
                           width="200"
                           @click="
                              disabledTalonario || checkingPagos
                                 ? false
                                 : generarReporteTalonario(
                                      'save',
                                      true,
                                      cuotasImpresion.toString()
                                   )
                           "
                        >
                           Descargar
                        </v-btn>
                        <v-btn
                           color="primary"
                           :disabled="disabledTalonario"
                           :class="
                              $vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4'
                           "
                           width="200"
                           @click="
                              disabledTalonario || checkingPagos
                                 ? false
                                 : generarReporteTalonario(
                                      $vuetify.breakpoint.smAndUp
                                         ? 'open'
                                         : 'save',
                                      true,
                                      cuotasImpresion.toString()
                                   )
                           "
                        >
                           Abrir en el Navegador
                        </v-btn>
                     </span>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-2">
                     <span
                        class="font-weight-medium colorPrimary"
                        style="font-size: 24px"
                     >
                        AVISO:
                     </span>
                     <span class="font-weight-medium" style="font-size: 24px">
                        Solo se mostrarán cuotas pendientes de cancelar.
                     </span>
                     <br />
                     <span class="font-weight-medium" style="font-size: 24px">
                        Puedes salir de esta página haciendo clic en el 👍.
                     </span>
                  </v-flex>
               </v-layout>

               <!-- SI TIENE MORA -->
               <v-layout v-if="poseeMora && showError" justify-center wrap>
                  <v-flex
                     xs12
                     class="text-center mt-8"
                     :style="
                        $vuetify.breakpoint.smAndUp
                           ? 'padding-left: 10%; padding-right: 10%'
                           : ''
                     "
                  >
                     <p class="font-weight-medium text-h4 text-justify">
                        Estimado alumno, actualmente cuenta con una mora
                        económica, recuerda que si realizaste el pago en
                        agencias Davivienda, corresponsales autorizados o en el
                        sistema de pagos en linea, debes esperar aproximadamente
                        72 horas hábiles.
                        <br /><br />
                        Si tienes alguna consulta al respecto puedes escribir a:
                        <br />
                        <span class="primaryColor">
                           {{ correoAdm }}
                        </span>
                     </p>
                  </v-flex>
                  <v-flex xs12 class="text-center mt-4">
                     <span class="font-weight-medium" style="font-size: 24px">
                        <v-btn color="primary" @click="sacameDeAqui"
                           >Salir</v-btn
                        >
                     </span>
                  </v-flex>
               </v-layout>
            </v-layout>
         </v-container>

         <!-- SI NO ESTA VERIFICADO -->
         <v-container v-if="!verificado" fluid>
            <v-layout justify-center wrap>
               <v-flex
                  xs12
                  class="text-center mt-8"
                  :style="
                     $vuetify.breakpoint.smAndUp
                        ? 'padding-left: 10%; padding-right: 10%'
                        : ''
                  "
               >
                  <p class="font-weight-medium text-h3">
                     {{ loadingMessage }}
                  </p>
               </v-flex>
            </v-layout>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { Base64 } from 'js-base64'
import { mask } from 'vue-the-mask'
import { authComputed } from '../helpers'
const getReporteLib = () => import('../mixins/reporteLib')

export default {
   name: 'ReporteTalonario',
   directives: {
      mask
   },
   mixins: [formatters],
   data: () => ({
      // Variables
      correoAdm: process.env.VUE_APP_CORREO_ADM,
      disabledTalonario: false,
      checkingPagos: false,
      cicloActual: null,
      cicloPasado: null,
      showError: false,
      dialogReporteLoading: false,
      pagoExtemporaneo: 0.0,
      loadingMessage: '',
      alumnoMessage: 'Cargando...',
      talonario: [],
      aranceles: [],
      cuotasImpresion: [],
      verificado: false,
      carnet: '',
      accesscode: '',
      dialogSyncLoading: false,
      poseeMora: true,
      pagoAlu: [],
      selectCiclo: '',
      cmbCiclos: [],
      selectFiltro: 1,
      cmbFiltros: [
         {
            id: 1,
            nombre: 'Solo cuotas pendientes'
         },
         {
            id: 2,
            nombre: 'Completo'
         }
      ],

      // Snackbar
      snackbar: false,
      snackText: '',
      snackColor: 'primary'
   }),
   computed: {
      ...authComputed
   },
   watch: {
      talonario(val) {
         // Revisar si ya ha pagado alguna cuota
         this.checkingPagos = true
         this.cuotasImpresion = []

         if (val == undefined) {
            this.disabledTalonario = true
         }

         // Buscar pagos en web
         axios
            .get('api/Pago?Carnet=' + this.carnet + '&ResponseCode=00')
            .then((response) => {
               this.pagoAlu = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               val.contratoPlanPagos.forEach((c) => {
                  var res = this.pagoAlu.filter((obj) => {
                     return obj.npe == c.npe
                  })
                  if (!c.facturado && res.length == 0) {
                     this.cuotasImpresion.push(c.cuota)
                  }
               })
               if (this.cuotasImpresion.length == 0) {
                  this.disabledTalonario = true
               }
               this.checkingPagos = false
            })
      },
      selectCiclo() {
         if (this.checkRangoAlto()) {
            this.obtenerTalonarioAdmin()
         }
         this.checkingPagos = true
         this.cuotasImpresion = []
      }
   },
   created() {
      this.listarComboboxes()
   },
   methods: {
      listarComboboxes() {
         axios
            .get('api/Configuracion/cicloActual')
            .then(() => {
               // Skip
            })
            .catch(() => {
               //console.log(error)
            })
            .finally(() => {
               // Obteniendo imagenes base64 para impresiones
               this.toDataURL('/assets/logo_u_black-300.png').then((result) => {
                  this.logo64 = result
               })
               this.toDataURL('/assets/CdA_black-300.png').then((result) => {
                  this.cda64 = result
               })
               this.getQueryVariables()
            })

         axios
            .get('api/Ciclos')
            .then((response) => {
               this.cmbCiclos = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Consulta para revision de morosidad del ciclo pasado
      revisarMorosidad() {
         axios
            .get(
               'api/Contrato/Cuotas?Carnet=' +
                  this.carnet +
                  '&Facturado=false&PeriodoLectivo=' +
                  this.cicloPasado
            )
            .then((response) => {
               this.cuotasConMora = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               if (this.cuotasConMora.length > 0) {
                  // Mostrar mensajes de mora
                  axios
                     .get('api/Pago?Carnet=' + this.carnet + '&ResponseCode=00')
                     .then((response) => {
                        this.pagoAlu = response.data
                     })
                     .catch((error) => {
                        console.log(error)
                     })
                     .finally(() => {
                        this.checkPagos()
                     })

                  this.alumnoMessage = ''
                  this.showError = true
                  //me.obtenerTalonario()
               } else {
                  // Obtener talonario para reporte
                  this.alumnoMessage = ''
                  this.poseeMora = false
                  this.obtenerTalonario()
               }
            })
      },
      // Obtener informacion de talonario
      obtenerTalonario() {
         //console.log(me.carnet + me.ciclo)
         axios
            .get(
               'api/Contrato?Carnet=' +
                  this.carnet +
                  '&Ciclo=' +
                  this.cicloActual
            )
            .then((response) => {
               this.talonario = response.data[0]
               this.aranceles = this.talonario
                  ? this.talonario.detalleContrato
                  : []
            })
            .catch((error) => {
               console.log(error)
            })
      },
      obtenerTalonarioAdmin() {
         //console.log(me.carnet + me.ciclo)
         axios
            .get(
               'api/Contrato?Carnet=' +
                  this.carnet +
                  '&Ciclo=' +
                  this.selectCiclo
            )
            .then((response) => {
               this.talonario = response.data[0]
               this.aranceles = this.talonario
                  ? this.talonario.detalleContrato
                  : []
               this.poseeMora = false
               this.disabledTalonario = false
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Generar pdf
      generarReporteTalonario(mode, portadas = true, filtroCuotas = null) {
         var filteredDetalles = []

         // Filtrando cuotas si es solicitado
         if (filtroCuotas || filtroCuotas === '') {
            var res = filtroCuotas.split(',').map(function (item) {
               return item.trim()
            })
            filteredDetalles = this.talonario.contratoPlanPagos.filter((el) =>
               res.includes(el.cuota.toString())
            )

            filteredDetalles =
               filteredDetalles.length == 0 ? [] : filteredDetalles
         } else {
            filteredDetalles = this.talonario.contratoPlanPagos.sort(
               this.dynamicSort('cuota')
            )
         }

         const getDetalleAranceles = (cuota) => {
            var result = []
            this.pagoExtemporaneo = Number(cuota.monto) + 12
            var fS = this.aranceles.length < 5 ? 8 : 7
            this.aranceles.forEach((arancel) => {
               // Imprimiendo aranceles (U)nicos solo en primera cuota
               if (
                  arancel.tipoCobro == 'U' &&
                  cuota.cuota == 1 &&
                  !arancel.extraordinaria &&
                  !arancel.procesoGraduacion &&
                  !this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }

               // Impriendo aranceles (R)ecurrentes en todas las cuotas
               if (
                  arancel.tipoCobro == 'R' &&
                  !arancel.extraordinaria &&
                  !arancel.procesoGraduacion &&
                  !this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }

               // Impriendo arancel de proceso gracuadion si el talonario esta en proceso de graduacion
               if (
                  arancel.tipoCobro == 'R' &&
                  !arancel.extraordinaria &&
                  arancel.procesoGraduacion &&
                  this.talonario.procesoGraduacion
               ) {
                  result.push([
                     {
                        text: arancel.articulo,
                        alignment: 'right',
                        fontSize: fS
                     },
                     {
                        text: arancel.descripcion.replace('_', ' '),
                        fontSize: fS
                     },
                     { text: '$', fontSize: fS },
                     {
                        text: this.toFixedRegex(
                           Number(arancel.precioUnitario) +
                              Number(arancel.montoImpuesto),
                           2
                        ),
                        fontSize: fS,
                        margin: [0, 0, 35, 0],
                        alignment: 'right'
                     }
                  ])
               }
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: cuota.monto,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getTalonarioBody = (showPortadas) => {
            var resultPage = []

            // ------------------------------------ PORTADA ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 120, '*'],
                        body: [
                           [
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'NUESTRA MISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 rowSpan: 2,
                                 width: 90
                              },
                              {
                                 text: 'NUESTRA VISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    'La formación de personas, inspirada en los principios cristianos y en los conocimientos' +
                                    ' técnicos y científicos, orientada a una constante búsqueda de la verdad y del uso del saber,' +
                                    ' para contribuir a la tutela y desarrollo de la dignidad humana y de la sociedad,' +
                                    ' mediante la investigación, docencia y la proyección social.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    'Seremos una universidad con excelentes servicios de educación superior, líderes en la' +
                                    ' formación integral de la persona humana y de la sociedad, dentro de las' +
                                    ' exigencias de la verdad y del bien común.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 table: {
                                    widths: [150, 60, '*', 90],
                                    body: [
                                       [
                                          {},
                                          {
                                             text: 'CARNET:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.cliente
                                          },
                                          {
                                             image: this.cda64,
                                             alignment: 'center',
                                             rowSpan: 4,
                                             width: 90
                                          }
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'NOMBRE:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.nombre
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'FACULTAD:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario
                                                .unidadEducativa
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'CARRERA:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.gradoAcademico
                                          },
                                          {}
                                       ]
                                    ]
                                 },
                                 layout: 'noBorders',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 16,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'TALONARIO UNICAMENTE PARA CICLO: ' +
                                    this.talonario.periodoLectivo,
                                 alignment: 'center',
                                 fontSize: '20',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'Talonario No. ' +
                                    this.talonario.codigoTalonario,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     colSpan: 2
                  },
                  {}
               ])
            }

            // ------------------------------------ CUOTAS ----------------------------------------
            filteredDetalles.forEach((cuota) => {
               resultPage.push([
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.nombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.gradoAcademico,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.periodoLectivo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.cuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.unidadEducativa,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   this.pagoExtemporaneo,
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' +
                                       this.talonario.codigoTalonario,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'ALUMNO',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [0, 0, 15, 0],
                        layout: 'noBorders'
                     }
                  ],
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.cliente,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.nombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.gradoAcademico,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.periodoLectivo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.cuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.unidadEducativa,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   this.pagoExtemporaneo,
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' +
                                       this.talonario.codigoTalonario,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'UNIVERSIDAD',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [15, 0, 0, 0],
                        layout: 'noBorders'
                     }
                  ]
               ])
            })

            // ------------------------------------ REVERSO ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 175, '*'],
                        body: [
                           [
                              {
                                 text: 'TALONARIO DE PAGOS',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'INSTRUCCIONES',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 width: 90,
                                 rowSpan: 2
                              },
                              {
                                 text: 'RECUERDE QUE',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    '1.    Los pagos deberán ser cancelados en el Banco que la Universidad autorice para realizar' +
                                    ' los cobros de los alumnos.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    '1.    El vencimiento de las cuotas está programado en su talonario de pago y en el calendario' +
                                    ' académico. (Art. 29 del reglamento de evaluación).',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '2.    Es responsabilidad del alumno verificar que el cajero firme y selle los recibos' +
                                    ' correspondiente a los pagos que realice en el Banco',
                                 alignment: 'justify'
                              },
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 bold: true,
                                 alignment: 'center',
                                 fontSize: 13
                              },
                              {
                                 text:
                                    '2.    Las matriculas y las cuotas canceladas en fechas posteriores a el vencimiento tendrán' +
                                    ' complemento de $12.00 y $12.00 respectivamente dicha medida será aplicada por el Banco automáticamente.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '\n3.    En caso de extravío del talonario de pagos deberá de solicitar una reposición' +
                                    ' en la colecturía de la Universidad, previo a pago respectivo.',
                                 alignment: 'justify'
                              },
                              {
                                 text:
                                    'By pass a Metapán y Carretera antigua a San Salvador\n' +
                                    'PBX: (503) 2484-0600\nFax: (503) 2441-2655,\nSanta Ana, El Salvador\n' +
                                    'Web: http://www.catolica.edu.sv\nE-mail: catolica@catolica.edu.sv',
                                 alignment: 'center',
                                 bold: true,
                                 fontSize: 11
                              },
                              { text: '', alignment: 'justify' }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'Talonario No. ' +
                                    this.talonario.codigoTalonario,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     pageBreak: 'before',
                     colSpan: 2
                  },
                  {}
               ])
            }

            return resultPage
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: getTalonarioBody(portadas)
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename = `Talonario Ciclo ${this.talonario.periodoLectivo} ${this.talonario.cliente}.pdf`
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      // Decodificar codigo base64 de codigo de barras
      decodeString() {
         this.encoded64 = Base64.decode(this.encoded64)
      },
      // Convierte imagenes a base64 para reporte
      toDataURL(url) {
         return new Promise(function (resolve) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
               var reader = new FileReader()
               reader.onloadend = function () {
                  resolve(reader.result)
               }
               reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
         })
      },
      // Obtiene variables de queryString
      getQueryVariables() {
         this.carnet = this.$route.query.carnet
         this.accesscode = this.$route.query.accesscode
         this.verificado = false
         this.loadingMessage = 'Verificando alumno...'
         this.alumnoMessage = ''

         setTimeout(() => {
            axios
               .get(
                  `api/Contrato/Cuotas?Carnet=${this.carnet}&IncluirDeuda=true&Actual=true`
               )
               .then((response) => {
                  var detallesPendientes = response.data
                  if (detallesPendientes.length > 0) {
                     this.cicloActual = detallesPendientes[0].periodoLectivo
                     var cicloSplit = this.cicloActual.split('-')
                     if (cicloSplit[0] == 'I') {
                        this.cicloPasado = 'II-' + (parseInt(cicloSplit[1]) - 1)
                     } else {
                        this.cicloPasado = 'I-' + cicloSplit[1]
                     }
                     this.selectCiclo = this.cicloActual
                  }
               })
               .catch(() => {
                  //console.log(error)
               })
               .finally(() => {
                  // Revisar identidad del alumno
                  axios
                     .get(
                        'api/ValidarAlumno?Carnet=' +
                           this.carnet +
                           '&AccessCode=' +
                           this.accesscode
                     )
                     .then((response) => {
                        if (response.data[0] !== undefined) {
                           this.verificado = true
                           this.revisarMorosidad()
                        } else {
                           this.loadingMessage = 'Datos incorrectos'
                           this.verificado = false
                        }
                     })
                     .catch((error) => {
                        console.log(error)
                     })
               })
         }, 50)
      },
      // Redireccionar alumno a pagina externa
      sacameDeAqui() {
         window.location = process.env.VUE_APP_OUTURL
      },
      // Redireccionar alumno a formulario de plan de pago
      irPlanDePago() {
         window.location = process.env.VUE_APP_CLIENTE + 'formulario'
      },
      checkPagos() {
         if (this.pagoAlu.length > 0) {
            for (let i = 0; i < this.cuotasConMora.length; i++) {
               for (let e = 0; e < this.pagoAlu.length; e++) {
                  if (this.cuotasConMora[i].npe == this.pagoAlu[e].npe) {
                     this.cuotasConMora.splice(i, 1)
                  }
               }
            }
            if (this.cuotasConMora.length > 0) {
               this.poseeMora = true
            } else {
               this.alumnoMessage = 'Felicidades '
               this.poseeMora = false
               this.obtenerTalonario()
            }
         } else {
            this.poseeMora = true
         }
      },
      checkRangoAlto() {
         if (this.oidcUser) {
            return Number(this.oidcUser.nivelasesor) > 1 ||
               this.oidcUser.role == 'admin'
               ? true
               : false
         } else {
            return false
         }
      }
   }
}
</script>

<style scoped>
.tiltImage {
   transition: transform 0.2s ease-in-out;
}
.tiltImage:hover {
   transform: rotateZ(-5deg);
}
.primaryColor {
   color: var(--v-primary-base) !important;
}
</style>
