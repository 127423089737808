var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.verificado)?_c('v-container',{attrs:{"fluid":""}},[(_vm.checkRangoAlto())?_c('v-layout',{staticClass:"text-center mt-2",attrs:{"justify-center":"","wrap":"","xs4":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-autocomplete',{staticClass:"mr-1",attrs:{"items":_vm.cmbCiclos,"color":"primary","item-text":"nombre","item-value":"nombre","label":"Ciclos","no-data-text":"Sin resultados","placeholder":"Seleccione un ciclo..."},model:{value:(_vm.selectCiclo),callback:function ($$v) {_vm.selectCiclo=$$v},expression:"selectCiclo"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-autocomplete',{staticClass:"ml-1",attrs:{"items":_vm.cmbFiltros,"color":"primary","item-text":"nombre","item-value":"id","label":"Filtro","no-data-text":"Sin resultados","placeholder":"Seleccionar..."},model:{value:(_vm.selectFiltro),callback:function ($$v) {_vm.selectFiltro=$$v},expression:"selectFiltro"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{staticClass:"tiltImage",attrs:{"src":"/assets/thumbs-up-400.png","contain":"","height":"145"}})],1),_c('v-flex',{staticClass:"text-center mt-8",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium text-h3"},[_vm._v(" "+_vm._s(_vm.talonario === undefined ? 'TALONARIO AUN NO GENERADO' : _vm.talonario.nombre)+" ")])]),_c('v-flex',{staticClass:"text-center mt-2",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_vm._v(" Obtén tu talonario "+_vm._s(_vm.selectCiclo)+". "),_c('br'),_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4',attrs:{"color":"primary","disabled":_vm.checkingPagos,"width":"200"},on:{"click":function($event){_vm.checkingPagos
                              ? false
                              : _vm.generarReporteTalonario(
                                   'save',
                                   true,
                                   _vm.selectFiltro == 1
                                      ? _vm.cuotasImpresion.toString()
                                      : null
                                )}}},[_vm._v(" Descargar ")]),_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4',attrs:{"color":"primary","disabled":_vm.checkingPagos,"width":"200"},on:{"click":function($event){_vm.checkingPagos
                              ? false
                              : _vm.generarReporteTalonario(
                                   _vm.$vuetify.breakpoint.smAndUp
                                      ? 'open'
                                      : 'save',
                                   true,
                                   _vm.selectFiltro == 1
                                      ? _vm.cuotasImpresion.toString()
                                      : null
                                )}}},[_vm._v(" Abrir en el Navegador ")])],1)]),_c('v-flex',{staticClass:"text-center mt-2",attrs:{"xs12":""}},[_c('br'),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_vm._v(" Puedes salir de esta página haciendo clic en el 👍. ")])])],1)],1):_c('v-layout',[(!_vm.poseeMora)?_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{staticClass:"tiltImage",attrs:{"src":"/assets/thumbs-up-400.png","contain":"","height":"145"},on:{"click":_vm.sacameDeAqui}})],1),_c('v-flex',{staticClass:"text-center mt-8",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium text-h3"},[_vm._v(" "+_vm._s(_vm.talonario === undefined ? 'TALONARIO AUN NO GENERADO' : _vm.talonario.nombre)+" ")])]),_c('v-flex',{staticClass:"text-center mt-2",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_vm._v(" Obtén tu talonario "+_vm._s(_vm.cicloActual)+". "),_c('br'),_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4',attrs:{"color":"primary","disabled":_vm.disabledTalonario,"width":"200"},on:{"click":function($event){_vm.disabledTalonario || _vm.checkingPagos
                              ? false
                              : _vm.generarReporteTalonario(
                                   'save',
                                   true,
                                   _vm.cuotasImpresion.toString()
                                )}}},[_vm._v(" Descargar ")]),_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp ? 'ml-2 mt-4' : 'mt-4',attrs:{"color":"primary","disabled":_vm.disabledTalonario,"width":"200"},on:{"click":function($event){_vm.disabledTalonario || _vm.checkingPagos
                              ? false
                              : _vm.generarReporteTalonario(
                                   _vm.$vuetify.breakpoint.smAndUp
                                      ? 'open'
                                      : 'save',
                                   true,
                                   _vm.cuotasImpresion.toString()
                                )}}},[_vm._v(" Abrir en el Navegador ")])],1)]),_c('v-flex',{staticClass:"text-center mt-2",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium colorPrimary",staticStyle:{"font-size":"24px"}},[_vm._v(" AVISO: ")]),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_vm._v(" Solo se mostrarán cuotas pendientes de cancelar. ")]),_c('br'),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_vm._v(" Puedes salir de esta página haciendo clic en el 👍. ")])])],1):_vm._e(),(_vm.poseeMora && _vm.showError)?_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticClass:"text-center mt-8",style:(_vm.$vuetify.breakpoint.smAndUp
                        ? 'padding-left: 10%; padding-right: 10%'
                        : ''),attrs:{"xs12":""}},[_c('p',{staticClass:"font-weight-medium text-h4 text-justify"},[_vm._v(" Estimado alumno, actualmente cuenta con una mora económica, recuerda que si realizaste el pago en agencias Davivienda, corresponsales autorizados o en el sistema de pagos en linea, debes esperar aproximadamente 72 horas hábiles. "),_c('br'),_c('br'),_vm._v(" Si tienes alguna consulta al respecto puedes escribir a: "),_c('br'),_c('span',{staticClass:"primaryColor"},[_vm._v(" "+_vm._s(_vm.correoAdm)+" ")])])]),_c('v-flex',{staticClass:"text-center mt-4",attrs:{"xs12":""}},[_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"24px"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.sacameDeAqui}},[_vm._v("Salir")])],1)])],1):_vm._e()],1)],1):_vm._e(),(!_vm.verificado)?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{staticClass:"text-center mt-8",style:(_vm.$vuetify.breakpoint.smAndUp
                     ? 'padding-left: 10%; padding-right: 10%'
                     : ''),attrs:{"xs12":""}},[_c('p',{staticClass:"font-weight-medium text-h3"},[_vm._v(" "+_vm._s(_vm.loadingMessage)+" ")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }